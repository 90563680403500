import React, { FC } from "react"
import styled from "styled-components"
import Wrapper from "~/components/AboutPage/utils/Wrapper"
import TopicLabel from "~/components/AboutPage/utils/TopicLabel"
import Horizon from "~/components/AboutPage/utils/Horizon"

const TextBox = styled.div`
  width: ${({ theme }) => theme.width.aboutPageX};
  margin: ${({ theme }) => theme.space.aboutPageSpace} auto;
  ${({ theme }) => theme.media.tab`
    width: 90%;
  `}
`

const Text = styled.p`
  margin: 0;
`

const WhatTourism: FC = () => {
  return (
    <Wrapper>
      <TopicLabel>Tourismプロジェクトとは？</TopicLabel>
      <TextBox>
        <Text>
          Tourism
          プロジェクトは2020年1月に発足された金沢工業大学で活動している非公式のプロジェクトです。
        </Text>
        <Text>
          私たちは「地域 ×
          作品」をテーマに、地域活性化にアニメや映画などの作品の力をかけ合わせて貢献することを目的として活動しています。
        </Text>
      </TextBox>
      <Horizon />
    </Wrapper>
  )
}

export default WhatTourism
