import React, { FC } from "react"
import styled from "styled-components"
import Wrapper from "~/components/AboutPage/utils/Wrapper"
import TopicLabel from "~/components/AboutPage/utils/TopicLabel"
import Title from "~/components/AboutPage/utils/Title"
import DetailList from "~/components/AboutPage/utils/DetailList"
import Horizon from "~/components/AboutPage/utils/Horizon"
import TechLabel from "~/components/TagLabel"

const TextList = {
  work: {
    title: "活動内容",
    sentences: [
      "スマートフォンのアプリケーション開発",
      "位置情報を用いたスタンプラリーの実装",
      "写真を用いたスタンプ機能の実装",
    ],
  },
  tech: {
    title: "使用技術",
    langList: ["Dart", "Flutter", "SQLite", "Firebase", "Github"],
  },
}

const LabelBox = styled.div`
  margin: ${({ theme }) => theme.space.aboutPageSpace} auto;
  width: ${({ theme }) => theme.width.aboutPageX};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${({ theme }) => theme.media.tab`
    width: 90%;
  `}
`

const AppTeam: FC = () => {
  return (
    <Wrapper>
      <TopicLabel>アプリ班とは？</TopicLabel>

      <Title>{TextList.work.title}</Title>
      <DetailList textList={TextList.work.sentences} />
      <Horizon />

      <Title>{TextList.tech.title}</Title>
      <LabelBox>
        {TextList.tech.langList.map(lang => (
          <TechLabel tech={lang} key={lang} />
        ))}
      </LabelBox>
      <Horizon />
    </Wrapper>
  )
}

export default AppTeam
