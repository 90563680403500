import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  textList: string[]
}

const ListBox = styled.div`
  text-align: center;
`

const UnorderedList = styled.ul`
  margin: 50px 20%;
  padding-inline-start: 30px;
  display: inline-block;
  text-align: left;
  ${({ theme }) => theme.media.tab`
    margin: 50px 0;
  `}
`

const ListItem = styled.li`
  margin: 5px 0;
`

const DetailList: FC<Props> = ({ textList }) => {
  return (
    <ListBox>
      <UnorderedList>
        {textList.map((text, index) => (
          <ListItem key={index}>{text}</ListItem>
        ))}
      </UnorderedList>
    </ListBox>
  )
}

export default DetailList
