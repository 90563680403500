import React, { FC } from "react"
import styled from "styled-components"

const ContentsList = [
  {
    label: "活動日",
    content: "土曜20時からミーティング",
  },
  {
    label: "活動場所",
    content: "オンライン（対面時は金沢工業大学内の施設を利用）",
  },
  {
    label: "所属メンバー",
    content: "10名（2022年9月現在）",
  },
  {
    label: "使用ツール",
    content: "ZOOM、Slack、Googleサービス etc...",
  },
]

const Wrapper = styled.div`
  width: ${({ theme }) => theme.width.aboutPageX};
  margin: ${({ theme }) => theme.space.aboutPageSpace} auto;
  ${({ theme }) => theme.media.tab`
    width: 90%;
  `}
`

const Title = styled.div`
  margin: 10px auto;
  display: flex;
  align-items: center;
  &:before,
  &:after {
    content: "";
    height: 1px;
    flex-grow: 1;
    background-color: #666;
  }
  &:before {
    margin-right: 30px;
  }
  &:after {
    margin-left: 30px;
  }
`

const Table = styled.table`
  margin: 10px auto;
  border-collapse: collapse;
  th,
  td {
    border: solid 1px black;
    padding: 10px;
  }
`

const Info: FC = () => {
  return (
    <Wrapper>
      <Title>プロジェクト情報</Title>
      <Table>
        <tbody>
          {ContentsList.map(content => (
            <tr key={content.label}>
              <td>{content.label}</td>
              <td>{content.content}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  )
}

export default Info
