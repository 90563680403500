import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  tech: string
}

const Label = styled.div`
  margin: 5px 10px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;

  &[data-level="プロジェクト"] {
    color: white;
    background-color: #8e7cc3;
    border-color: #8e7cc3;
  }

  &[data-level="イベント"] {
    color: black;
    background-color: #f9cb9a;
    border-color: #f9cb9a;
  }

  &[data-level="新入生"] {
    color: black;
    background-color: #ffdbed;
    border-color: #ffdbed;
  }

  &[data-level="アプリ"] {
    color: white;
    background-color: #929292;
    border-color: #929292;
  }

  &[data-level="Web"] {
    color: black;
    background-color: #a2c4c9;
    border-color: #a2c4c9;
  }

  &[data-level="趣味"] {
    color: white;
    background-color: #783f04;
    border-color: #783f04;
  }

  &[data-level="雑談"] {
    color: white;
    background-color: #b4533c;
    border-color: #b4533c;
  }

  &[data-level="アルゴリズム"] {
    color: white;
    background-color: #93c47d;
    border-color: #93c47d;
  }

  &[data-level="Python"] {
    color: black;
    background-color: #fcdf57;
    border-color: #fcdf57;
  }

  &[data-level="Dart"] {
    color: white;
    background-color: #054d86;
    border-color: #054d86;
  }

  &[data-level="Flutter"] {
    color: white;
    background-color: #54c4fb;
    border-color: #54c4fb;
  }

  &[data-level="Swift"] {
    color: white;
    background-color: #f4543c;
    border-color: #f4543c;
  }

  &[data-level="SQLite"] {
    color: black;
    background-color: #cfe2f3;
    border-color: #cfe2f3;
  }

  &[data-level="HTML / CSS"] {
    color: white;
    background-color: #ff9900;
    border-color: #ff9900;
  }

  &[data-level="JavaScript / TypeScript"] {
    color: white;
    background-color: #2c7cc4;
    border-color: #2c7cc4;
  }

  &[data-level="Vue.js / NuxtJS"] {
    color: white;
    background-color: #3cbc84;
    border-color: #3cbc84;
  }

  &[data-level="React / GatsbyJS"] {
    color: white;
    background-color: #571C90;
    border-color: #571C90;
  }

  &[data-level="React / Next.js"] {
    color: black;
    background-color: #64dcfc;
    border-color: #64dcfc;
  }

  &[data-level="Firebase"] {
    color: black;
    background-color: #fac724;
    border-color: #fac724;
  }

  &[data-level="Github"] {
    color: white;
    background-color: #141414;
    border-color: #141414;
  }

  &[data-level="Markdown"] {
    color: black;
    background-color: #f3f3f3;
    border-color: #f3f3f3;
  }
`

const TechLabel: FC<Props> = ({ tech }) => {
  return <Label data-level={tech}>{tech}</Label>
}

export default TechLabel
