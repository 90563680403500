import React, { FC } from "react"
import styled from "styled-components"

const Line = styled.hr`
  margin-top: 50px;
  width: ${({ theme }) => theme.width.aboutPageX};
  ${({ theme }) => theme.media.tab`
    width: 90%;
  `}
`

const Horizon: FC = () => {
  return <Line />
}

export default Horizon
