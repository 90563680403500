import React, { FC } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import HelpIcon from "@mui/icons-material/Help"
import InternalLink from "~/components/InternalLink"
import ExternalLink from "~/components/ExternalLink"

const Wrapper = styled.div`
  width: ${({ theme }) => theme.width.aboutPageX};
  margin: ${({ theme }) => theme.space.aboutPageSpace} auto;
  ${({ theme }) => theme.media.tab`
    width: 90%;
  `}
`

const Text = styled.div`
  margin: 50px auto;

  &[data-level="link"] {
    text-align: center;
    color: blue;
    font-size: 2rem;
    &:hover {
      opacity: 0.5;
    }
  }
`

const Links = styled.div`
  margin-bottom: 100px;
  display: flex;
  justify-content: space-around;
`

const PageFooter: FC = () => {
  return (
    <Wrapper>
      <ExternalLink link="https://forms.gle/fZ6XD73C9DbevLhF7">
        <Text data-level="link">個別説明会のお申し込みはこちら！</Text>
      </ExternalLink>
      <Text>
        お問い合わせは本サイトのお問い合わせページまたはTwitterのダイレクトメッセージから受け付けております！！
      </Text>
      <Links>
        <InternalLink link="/contact">
          <HelpIcon sx={{ fontSize: 80 }} />
        </InternalLink>
        <ExternalLink link="https://twitter.com/kit_tourism">
          <StaticImage
            src="../../../static/images/twitter-icon.png"
            alt="Twitterアイコン"
            width={80}
          />
        </ExternalLink>
      </Links>
    </Wrapper>
  )
}

export default PageFooter
