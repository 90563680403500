import React, { FC } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  text-align: center;
`

const ItemBox = styled.div`
  display: block;
`

const OfferText = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.blue};
  margin: 30px 0;
  padding: 10px;
  font-size: 2rem;
  font-weight: 600;
`

const PageHeader: FC = () => {
  return (
    <Wrapper>
      <ItemBox>
        <StaticImage
          src="../../../static/images/member.JPG"
          alt="集合写真"
          width={800}
          height={450}
        />
      </ItemBox>
      <ItemBox>
        <OfferText>プロジェクトメンバー募集中！！</OfferText>
      </ItemBox>
    </Wrapper>
  )
}

export default PageHeader
