import React, { FC } from "react"
import styled from "styled-components"
import Head from "~/components/Head"
import Layout from "~/components/Layout"
import PageHeader from "~/components/AboutPage/PageHeader"
import WhatTourism from "~/components/AboutPage/WhatTourism"
import WorkDetail from "~/components/AboutPage/WorkDetail"
import AppTeam from "~/components/AboutPage/AppTeam"
import WebTeam from "~/components/AboutPage/WebTeam"
import Recommend from "~/components/AboutPage/Recommend"
import Info from "~/components/AboutPage/Info"
import PageFooter from "~/components/AboutPage/PageFooter"

const Wrapper = styled.div`
  margin: 30px;
  font-size: 1.25rem;
  ${({ theme }) => theme.media.lm`
    font-size: 1rem;
  `}
`

const AboutPage: FC = () => {
  return (
    <>
      <Head title="About" />
      <Layout>
        <Wrapper>
          <PageHeader />
          <WhatTourism />
          <WorkDetail />
          <AppTeam />
          <WebTeam />
          <Recommend />
          <Info />
          <PageFooter />
        </Wrapper>
      </Layout>
    </>
  )
}

export default AboutPage
