import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  children: React.ReactNode
}

const Box = styled.div`
  margin: ${({ theme }) => theme.space.aboutPageSpace} 0;
`

const Wrapper: FC<Props> = ({ children }) => {
  return <Box>{children}</Box>
}

export default Wrapper
