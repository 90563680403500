import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  children: React.ReactNode
}

const Label = styled.h2`
  text-align: center;
`

const Title: FC<Props> = ({ children }) => {
  return <Label>{children}</Label>
}

export default Title
