import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  children: React.ReactNode
}

const LabelBox = styled.div`
  ${({ theme }) => theme.media.tab`
    text-align: center;
  `}
`

const Label = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.purple};
  color: white;
  margin-left: 10%;
  padding: 5px 10px;
  font-size: 1.5rem;
  transform: rotate(-10deg);
  width: 300px;
  text-align: center;
  ${({ theme }) => theme.media.tab`
    transform: rotate(0deg);
    width: 90%;
    margin: 0 auto;
  `}
`

const TopicLabel: FC<Props> = ({ children }) => {
  return (
    <LabelBox>
      <Label>{children}</Label>
    </LabelBox>
  )
}

export default TopicLabel
