import React, { FC } from "react"
import Wrapper from "~/components/AboutPage/utils/Wrapper"
import TopicLabel from "~/components/AboutPage/utils/TopicLabel"
import DetailList from "~/components/AboutPage/utils/DetailList"
import Horizon from "~/components/AboutPage/utils/Horizon"

const TextList = [
  "外部の人の意見や要望を聞いて、それに基づいて開発を行うという実践的な経験をしたい人",
  "スマートフォンまたはWebのチーム開発の経験をしたい人",
  "地域活性化に関わりたい人",
  "イベントの企画などに興味がある人",
  "アニメや映画、ドラマなどの作品が好きな人",
  "作品の聖地巡礼に興味がある人",
]

const Recommend: FC = () => {
  return (
    <Wrapper>
      <TopicLabel>こんな人におすすめ！</TopicLabel>
      <DetailList textList={TextList} />
      <Horizon />
    </Wrapper>
  )
}

export default Recommend
