import React, { FC } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Wrapper from "~/components/AboutPage/utils/Wrapper"
import TopicLabel from "~/components/AboutPage/utils/TopicLabel"
import Title from "~/components/AboutPage/utils/Title"
import DetailList from "~/components/AboutPage/utils/DetailList"
import Horizon from "~/components/AboutPage/utils/Horizon"

const TextList = {
  app: {
    title: "スタンプラリーアプリの開発",
    sentences: [
      "スタンプラリーをスマートフォンで行うことができ、アプリ内のカメラで特定の場所で撮影するとそれがスタンプになるアプリを、アプリ班とWeb班に別れて開発中",
      "デジタルフォトスタンプラリーに合わせてフォトコンテストなどのイベントを企画",
      "協力している湯涌町との話し合い・現地調査",
    ],
  },
  mtg: {
    title: "定期ミーティング",
    sentences: [
      "毎週に1回その週に行ったことの報告をする",
      "必要であれば活動についての話し合いを行う",
      "ZOOMで行う",
    ],
  },
  gattsuri: {
    title: "がっつりweek",
    sentences: [
      "長期休み期間に1週間程度集中的に開発を行う",
      "最後に別プロジェクトと合同で成果発表を行う",
      "期間中ZOOMを繋ぎ1日3~4時間程度開発をする",
      "コロナが落ち着いたら合宿でやるかも？",
    ],
  },
}

const ImgBox = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space.aboutPageSpace};
`

const WorkDetail: FC = () => {
  return (
    <Wrapper>
      <TopicLabel>どんな活動があるの？</TopicLabel>

      <Title>{TextList.app.title}</Title>
      <DetailList textList={TextList.app.sentences} />
      <Horizon />

      <Title>{TextList.mtg.title}</Title>
      <DetailList textList={TextList.mtg.sentences} />
      <ImgBox>
        <StaticImage
          src="../../../static/images/zoom-mtg.jpg"
          alt="ミーティングの様子"
          width={400}
        />
      </ImgBox>
      <Horizon />

      <Title>{TextList.gattsuri.title}</Title>
      <DetailList textList={TextList.gattsuri.sentences} />
      <Horizon />
    </Wrapper>
  )
}

export default WorkDetail
